<ngx-spinner name="cargando" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 17px; color: white; letter-spacing: 0.04rem">CARGANDO...</p>
</ngx-spinner>

<ngx-spinner name="creando-tienda" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 17px; color: white; letter-spacing: 0.04rem">CREANDO TIENDA...</p>
</ngx-spinner>

<ngx-spinner name="sms" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 17px; color: white; letter-spacing: 0.04rem">ENVIANDO SMS...</p>
</ngx-spinner>

<ngx-spinner name="guardando" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 17px; color: white; letter-spacing: 0.04rem">GUARDANDO...</p>
</ngx-spinner>

<ngx-spinner name="imagenes" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 17px; color: white; letter-spacing: 0.04rem">CARGANDO IMÁGENES...</p>
</ngx-spinner>

<ngx-spinner name="generando" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 18px; color: white; letter-spacing: 0.04rem">GENERANDO PDF...</p>
</ngx-spinner>

<ngx-spinner name="generando-enlace" [fullScreen]="true" type="ball-clip-rotate" size="medium" [showSpinner]="true">
	<p style="font-size: 18px; color: white; letter-spacing: 0.04rem">GENERANDO ENLACE...</p>
</ngx-spinner>

<p-toast [style]="{top:'3.9em'}"></p-toast>

<p-confirmDialog key="default" defaultFocus="accept" [baseZIndex]="99999" [styleClass]="'confirmDialog'" [appendTo]="'body'" #cd1 [breakpoints]="{'100px': '75vw', '300px': '100vw'}">
	<ng-template pTemplate="header">
		<img class="confirmImage" src="assets/iconos/calendario.png" alt="" />
	</ng-template>
	<ng-template pTemplate="footer">
		<button type="button" class="botonesConfirmacion" pButton label="No" (click)="cd1.reject()"></button>
		<button type="button" class="botonesConfirmacion p-confirm-dialog-accept" pButton label="Si" (click)="cd1.accept()"></button>
	</ng-template>
</p-confirmDialog>

<p-confirmDialog key="confirmEliminar" defaultFocus="accept" [baseZIndex]="99999" [styleClass]="'confirmDialog'" [appendTo]="'body'" #cd2 [breakpoints]="{'100px': '75vw', '300px': '100vw'}">
	<ng-template pTemplate="header">
		<img class="confirmImage" src="assets/iconos/tiendita.png" alt="" />
	</ng-template>
	<ng-template pTemplate="footer">
		<button type="button" class="botonesConfirmacion" pButton label="No" (click)="cd2.reject()"></button>
		<button type="button" class="botonesConfirmacion p-confirm-dialog-accept" pButton label="Si" (click)="cd2.accept()"></button>
	</ng-template>
</p-confirmDialog>

<p-confirmDialog key="exito" defaultFocus="accept" [baseZIndex]="99999" [styleClass]="'confirmDialog confirmExito'" [appendTo]="'body'" #cd3 [breakpoints]="{'100px': '75vw', '300px': '100vw'}">
	<ng-template pTemplate="header">
		<img class="confirmImage" src="assets/iconos/check-verde.png" alt="" />
	</ng-template>
	<ng-template pTemplate="footer">
		<button type="button" class="botonesConfirmacion p-confirm-dialog-accept" pButton label="Ir" (click)="cd3.accept()"></button>
	</ng-template>
</p-confirmDialog>
