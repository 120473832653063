/*eslint-disable*/
import {Injectable} from '@angular/core';
import {FireError, FireErrors} from 'interfaces/error.interface';
import {CustomConfirm, Doc, Property} from 'interfaces/helper.interface';
import {ConfirmationService, MessageService} from 'primeng/api';
import {initializeApp} from 'firebase/app';
import {environment} from 'src/environments/environment';
import {
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	collectionGroup,
	deleteDoc,
	doc,
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	getCountFromServer,
	getDoc,
	getDocs,
	getFirestore,
	query,
	QueryConstraint,
	setDoc,
	Timestamp
} from 'firebase/firestore';
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {DialogService} from 'primeng/dynamicdialog';
import {Dialog} from 'interfaces/dialog.interface';
import {Mensaje} from 'interfaces/mensaje.interface';
import {Carrito, ProductoCarrito} from 'interfaces/carrito.interface';
import {DomSanitizer, Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {Direccion} from 'interfaces/direccion.interface';
import {Observable, firstValueFrom, map} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User as FirebaseUser} from 'firebase/auth';
import {NgxSpinnerService} from 'ngx-spinner';
import {Negocio} from 'interfaces/negocio.interface';
import {WorldTime} from 'interfaces/worldtime.interface';
import {collectionData, where} from '@angular/fire/firestore';
import {Platform} from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class HelperService {
	private app = initializeApp(environment.firebaseConfig);
	private db = getFirestore(this.app);

	public doc = (collectionName: string, uid: string, ...pathSegments: string[]) => doc(this.db, collectionName, uid, ...pathSegments);
	public collection = (collectionName: string, ...pathSegments: string[]) => collection(this.db, collectionName, ...pathSegments);

	private storage = getStorage();

	//prettier-ignore
	constructor(
    private messageService: MessageService,
    private dialogService: DialogService,
    private confirmation: ConfirmationService,
    private title: Title,
    private meta: Meta,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private platform:Platform,
  ) {}

	showSpinner(name: string) {
		return this.spinner.show(name);
	}
	hideSpinner(name: string) {
		return this.spinner.hide(name);
	}

	/**
	 * Obtiene el enlace a la funcion de Firebase seleccionada.
	 * @param  {string} modo
	 * @returns string
	 */
	public middleware = (modo: string): string =>
		(<Property<string>>{
			debug: environment.api_url_debug,
			prod: environment.api_url_prod
		})[modo];

	/******************************************
	 ************ DOC CONTROLLER **************
	 ******************************************/

	/**
	 * Agrega un nuevo documento a Firebase
	 * @param Params
	 * @returns
	 */
	public addDoc = async (Params: Doc): Promise<DocumentReference<any>> =>
		await addDoc(this.collection(Params.collection, ...Params.pathSegments), Params.data).catch((err) => {
			console.log(err);
			throw err;
		});
	public count = async (collectionName: string, pathSegments: string[] = [], ...cond: QueryConstraint[]) =>
		await getCountFromServer(query(this.collection(collectionName, ...pathSegments), ...cond));

	/**
	 * Establece el documento mediante un ID de Firebase.
	 * @param {Doc} Params
	 * @returns Promise<void>
	 */
	public setDoc = async (Params: Doc): Promise<void> =>
		await setDoc(this.doc(Params.collection, Params.uid!, ...Params.pathSegments), Params.data, {merge: true});

	/**
	 * Elimina un documento en Firebase.
	 * @param {Doc} Params
	 * @returns
	 */
	public delDoc = async (Params: Doc) => await deleteDoc(this.doc(Params.collection, Params.uid!, ...Params.pathSegments));

	/******************************************
	 ************ FIRE CONTROLLER *************
	 ******************************************/
	/**
	 * Crear un nuevo id para un documento en firebase
	 * @param {AngularFirestoreCollection<T>} collection
	 * @returns {string}
	 */
	public crearId = (collectionName: string) => doc(this.collection(collectionName)).id;

	/**
	 * Obtiene una lista de documentos.
	 * @param  {string} collectionName
	 * @param  {string[]=[]} pathSegments
	 * @param  {QueryConstraint[]} ...cond
	 * @returns Promise
	 */
	public obtenerDocumentos = async (collectionName: string, pathSegments: string[] = [], ...cond: QueryConstraint[]): Promise<any[]> =>
		await getDocs(query(this.collection(collectionName, ...pathSegments), ...cond))
			.then((snapshot: any) => snapshot.docs.map((doc: any) => ({...doc.data(), id: doc.id})))
			.catch((error: any) => this.firebaseErrors({error: error}));

	/**
	 * Obtiene solo un documento segun el id del documento
	 * @param  {string} collectionName
	 * @param  {string} uid
	 * @param  {string[]} ...pathSegments
	 * @returns Promise
	 */
	//prettier-ignore
	public obtenerDocumento = async (collectionName: string, id: string, ...pathSegments: string[]): Promise<any> =>
  await getDoc(this.doc(collectionName, id, ...pathSegments))
    .then((docSnap: DocumentSnapshot<DocumentData>) => (docSnap.exists() ? ({...docSnap.data(), uid: docSnap.id}) : false))
    .catch((error: any) => this.firebaseErrors({error: error}));

	// eslint-disable-next-line max-len
	public obtenerNegocio$ = (uid: string): Observable<Negocio | null> =>
		collectionData(query(this.collection('negocios'), where('uid', '==', uid))).pipe(map((a) => (a.length > 0 ? (a[0] as Negocio) : null)));

	/**
	 * Obtiene los datos de una propiedad especifica del
	 * objeto negocio desde Firebase.
	 * @param  {string} collectionName
	 * @param  {string} campo
	 * @returns Promise
	 */
	public getInfo = async (collectionName: string, campo: string = 'all', ...cond: QueryConstraint[]): Promise<any[]> =>
		await this.obtenerDocumentos(collectionName, [], ...cond).then((res: any) =>
			res.map((doc: any) =>
				(<Property<Function>>{
					all: () => doc,
					uid: () => doc.uid,
					name: () => doc.name,
					identificador: () => doc.identificador,
					tiendas: () => ({photo: doc.photo, name: doc.name, identificador: doc.identificador}),
					identUID: () => ({uid: doc.uid, identificador: doc.identificador}),

					//Valor de verdad invertido para no dar acceso en caso de tener direccion, horario y telefono.
					confirmData: () => ({
						id: doc.id,
						direccion: doc.direccion.length === 0,
						telefono: !doc.confirmTel,
						horario: doc.horario_atencion?.length === 0,
						confirmTel: doc.confirmTel
					})
				})[campo]()
			)
		);

	/******************************************
	 ********** MESSAGE CONTROLLER ************
	 ******************************************/

	/**
	 * Muestra un mensaje de exito al usuario.
	 * @param  {string} message
	 * @returns Function
	 */
	public successMsg = (message: string): Function[] => this.showMsg({severity: 'success', summary: message, life: 5000});

	/**
	 * Muestra un mensaje de error al usuario.
	 * @param  {string} message
	 */
	public errorMsg = (message: string) => this.showMsg({severity: 'error', summary: message, life: 5000});

	/**
	 * Muestra un mensaje Toast al usuario
	 * @param props
	 * @returns
	 */
	public showMsg = (props: Object) => this.baseMsg({add: props});

	/**
	 * Limpia los mensajes actuales.
	 * @returns void
	 */
	public clearMsg = () => this.baseMsg({clear: 'clear'});

	/**
	 * Base de los mensajes personalizados.
	 * @param {Object} props Objeto
	 */
	public baseMsg = (props: Object) =>
		Object.keys(props).map(
			(action: string): Function =>
				(<Property<Function>>{
					add: () => this.messageService.add(Object.values(props)[0]),
					clear: () => this.messageService.clear()
				})[action]()
		);

	/**
	 * Devuelve los mensajes de error de Firebase en español.
	 * @param {string} firebaseError Mensaje
	 * @param {any} rawError Mensaje sin key
	 * @returns {FireError}
	 */
	private fireErrorMessages = (firebaseError: string, rawError?: any): FireError =>
		(<FireErrors>{
			'auth/email-already-exists': {
				type: 'email',
				message: 'El correo electrónico ya está en uso.'
			},
			'auth/user-not-found': {
				type: 'user',
				message: 'Usuario no encontrado.'
			},
			'auth/wrong-password': {
				type: 'password',
				message: 'Contraseña incorrecta.'
			},
			'auth/too-many-requests': {
				type: 'request',
				message:
					'El acceso a esta cuenta se ha deshabilitado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede volver a intentarlo más tarde.'
			},
			'auth/internal-error': {
				type: 'request',
				message: 'Error interno'
			}
		})[firebaseError] ?? {type: 'desconocido', message: rawError};

	/**
	 * Muestra un mensaje Toast con los errores de Firebase.
	 * @param {any} error
	 * @returns {void}
	 */
	public firebaseErrors = (Service: any): void => {
		let message: any;

		if (typeof Service.error == 'string') {
			message = {severity: 'error', summary: Service.error, life: 5000};
		} else {
			const errors = this.fireErrorMessages(Service.error.code, Service.error.message);
			message = {severity: 'error', summary: errors.message, life: 5000};
		}

		this.clearMsg();
		this.showMsg(message);
		throw Service.error;
	};

	/**
	 * Carga una imagen al Storage de Firebase
	 * @param  {File} file
	 * @param  {string} id
	 * @param  {string} carpeta
	 * @returns Promise
	 */
	public cargarImagen = async (file: File, id: string, carpeta: string): Promise<any> => {
		const milisegundos: number = Date.now();
		const storageRef = ref(this.storage, `${carpeta}/${id}/${milisegundos}.${this.extension(file.name)}`);

		return await uploadBytes(storageRef, file, {contentType: file.type}).then(async (snapshot) => ({
			message: 'Guardado con éxito.',
			url: await getDownloadURL(snapshot.ref)
		}));
	};

	/**
	 * Elimina los URLs de las imágenes
	 * @param {string[]} urls
	 * @returns Promise<Mensaje>
	 */
	public eliminarImagenes = async (urls: string[]): Promise<Mensaje> => {
		for (let url of urls) {
			await deleteObject(ref(this.storage, url));
		}

		return {message: 'Eliminado con éxito.'};
	};

	/**
	 * Obtiene la extension exacta del archivo subido.
	 * @param {string} archivo
	 * @returns {string}
	 */
	public extension = (archivo: string): string => archivo.slice(((archivo.lastIndexOf('.') - 1) >>> 0) + 2);

	/**
	 * Abre la ventana de dialogo del componente seleccionado.
	 * @param {string} Params Datos
	 * @returns {any}
	 */
	public openDialog = (Params: Dialog): any =>
		new Promise((resolve, reject) => {
			// Bloqueamos el scroll del body
			document.querySelector('body')?.classList.add('block-scroll');
			this.dialogService
				.open(Params.component(), {
					data: {
						modo: Params.formGroup ? 'editar' : 'crear',
						info: Params.formGroup,
						otherData: Params.otherData
					},
					modal: true,
					closable: Params.closeManual ? false : true,
					header: Params.titulo,
					contentStyle: Params.contentStyle ? Params.contentStyle : {'max-height': '500px', overflow: 'auto'},
					styleClass: `${Params.style}`,
					baseZIndex: 10000,
					showHeader: Params.closeManual ? false : true
				})
				.onClose.subscribe((res: any) => {
					// Desbloqueamos el scroll del body
					document.querySelector('body')?.classList.remove('block-scroll');
					if (res) {
						if (typeof Params.funcion === 'function') Params.funcion(res);
						this.clearMsg();

						// Seteamos un parametro para esconder el mensaje en tal caso.
						if (!res?.result?.notShowMessage) this.showMsg({severity: 'success', summary: res.message, life: 3000});
					}
					resolve(res?.result);
				});
		});

	/**
	 * Rompe con el binding en la asignacion.
	 * @param {any} data
	 * @returns any
	 */
	public OneWayBinding = (data: any): any => JSON.parse(JSON.stringify(data));

	/**
	 * Confirmación custom
	 * @param  {string} message
	 * @param  {any} accept
	 * @param  {any} reject?
	 * @param  {string} labelAccept?
	 * @param  {string} labelReject?
	 * @param  {string} header?
	 * @param  {string} key?
	 * @returns ConfirmationService
	 */
	public customConfirm = (params: CustomConfirm): ConfirmationService =>
		this.confirmation.confirm({
			message: params.message,
			key: params.key ?? 'default',
			header: params.header ?? 'Confirmación',
			accept: () => params.accept(),
			reject: params.reject ? params.reject() : undefined,
			acceptLabel: params.acceptLabel ?? 'Ir',
			rejectLabel: params.rejectLabel ?? 'No'
		});

	/**
	 * Transforma la estampa de tiempo de Firebase
	 * en formato 'Horas:Minutos'
	 * @param {Timestamp} stamp
	 * @returns string
	 */
	public fireDate = (stamp: Timestamp): string => {
		const fecha = new Date(stamp.seconds * 1000);
		const horas = fecha.getHours() < 10 ? `0${fecha.getHours()}` : fecha.getHours();
		const minutos = fecha.getMinutes() < 10 ? `0${fecha.getMinutes()}` : fecha.getMinutes();

		return `${horas}:${minutos}`;
	};

	/**
	 * Obtiene la fecha actual en formato DD/MM/AAAA de tipo cadena.
	 * @param {Date} fecha
	 * @returns string
	 */
	public fechaActual = async (): Promise<any> =>
		await firstValueFrom(this.http.post(`${this.middleware('prod')}/obtener/ntp/`, {}))
			.then((res: any) => {
				const fecha = res.fecha.datetime;
				return `${fecha[8]}${fecha[9]}/${fecha[5]}${fecha[6]}/${fecha[0]}${fecha[1]}${fecha[2]}${fecha[3]}`;
			})
			.catch((error: any) => this.firebaseErrors({error: error}));

	//;

	/**
	 * Muestra la página seleccionada en el menu Editar
	 * @param mostrarPagina
	 * @param index
	 * @returns void
	 */
	public mostrarPagina = (mostrarPagina: boolean[], index: number): void => {
		for (let i = 0; i < mostrarPagina.length; i++) {
			mostrarPagina[i] = false;
		}

		mostrarPagina[index] = true;
	};

	/**
	 * Muestra el precio del producto en pesos chilenos.
	 * @param {number} precio
	 * @returns string
	 */
	public precioLocal = (precio: number | undefined) => precio?.toLocaleString('es-CL', {style: 'currency', currency: 'CLP'});

	/**
	 * Recorta la descripciones largas.
	 * @param description
	 * @returns string
	 */
	public shortDescription = (description: string | undefined, limit: number = 16): string => {
		const oldDescription = description?.split('');
		const newDescription: string[] = [];

		if (oldDescription?.length! < limit) return oldDescription!.join('');

		oldDescription?.forEach((letter: string, i: number) => i < limit && newDescription.push(letter));
		return `${newDescription.join('')}...`;
	};

	/**
	 * Establece el titulo de la pagina actual.
	 * @param {string} titulo
	 * @returns {void}
	 */
	public setTitle = (titulo: string) => this.title.setTitle(titulo);
	/**
	 * @description Establece las tags de la pagina
	 * @param tags Tags a establecer.
	 * @returns
	 */
	public setMetaTags = (tags: MetaDefinition[]) => tags.forEach((t) => this.meta.updateTag(t));

	public getPosition = async (): Promise<Direccion['position']> =>
		new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(resp: any) => resolve({lng: resp.coords.longitude, lat: resp.coords.latitude}),
				(error: any) => reject(error)
			);
		});

	/**
	 * Comparte el enlace en distintas aplicaciones.
	 * @param {string} enlace
	 * @returns
	 */
	public compartirEnlace = (enlace: string) =>
		navigator.share
			? navigator
					.share({
						title: '',
						url: enlace
					})
					.catch((error: any) => console.log('Error sharing', error))
			: null;

	/**
	 * Enlace directo a WhatsApp
	 * @returns
	 */
	// eslint-disable-next-line @typescript-eslint/member-ordering
	public compartirWhatsApp = (telefono: string, negocio: Negocio, mensaje?: string, sanitized?: boolean) =>
		(<Property<Function>>{
			true: (numero: string = this.recortarNumero(telefono)) => {
				// how to remove spaces from a stirng in typescript?
				const numeroListo = numero.replace(/\s+/g, '').replace('+', '');
				let url = '';
				if (this.platform.is('desktop')) {
					url = `https://web.whatsapp.com/send?phone=${numeroListo}&text=${encodeURI(mensaje || 'Hola necesito información')}`;
				} else {
					url = `https://wa.me/${numeroListo}?text=${encodeURI(mensaje || 'Hola necesito información')}`;
				}

				if (sanitized) {
					return this.sanitizer.bypassSecurityTrustUrl(url);
				}
				return url;
			},
			false: () => ''
		})[negocio?.confirmTel.toString()]();

	/**
	 * Comparte el enlace de la cotizacion por WhatsApp
	 * @param mensaje
	 * @returns
	 */
	public compartirCotizacionWhatsApp = (mensaje: string, telefono: string) =>
		`https://wa.me/${telefono.replace(/\s/g, '')}?text=${mensaje}`;

	/**
	 * Ayuda a prevenir el Cross Site Scripting
	 * @param {string} url
	 * @returns
	 */
	private sanitize = (url: string) => this.sanitizer.bypassSecurityTrustUrl(url);

	/**
	 * Elimina el primer caracter del numero de telefono.
	 * @param telefono
	 * @param tlfArray
	 * @returns
	 */
	private recortarNumero = (telefono: string, tlfArray: string[] = telefono?.split('')): string =>
		tlfArray?.shift() ? tlfArray.join('') : '';

	/**
	 * Verifica que la direccion obtenida sea una ciudad.
	 * @param {string} ciudad
	 * @returns
	 */
	public obtenerCiudades = async (ciudad: string) =>
		await firstValueFrom(this.http.post(`${this.middleware('prod')}/obtener/ciudades/`, {ciudad: ciudad}));
}
/* applyMixins(HelperService, [DocController, FirebaseController, MessageController]);

export interface HelperService extends DocController, FirebaseController, MessageController {} */

