/**
 * CORE
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

/**
 * COMPONENTE PRINCIPAL
 */
import {MensajesPage} from './mensajes.page';

/**
 * PRIMENG
 */
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';

/**
 * NGX
 */
import {NgxSpinnerModule} from 'ngx-spinner';

//prettier-ignore
@NgModule({
	declarations: [MensajesPage],
	imports: [
    CommonModule,
    ConfirmDialogModule,
    NgxSpinnerModule,
    ToastModule
  ],
	exports: [MensajesPage],
	providers: []
})

export class MensajesModule {}
