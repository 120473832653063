import {Injectable} from '@angular/core';
import {HelperService} from 'helpers/helper.service';
import {Property} from 'interfaces/helper.interface';
import {Negocio} from 'interfaces/negocio.interface';
import {getFunctions, HttpsCallable, httpsCallable} from 'firebase/functions';
import {DocumentData, DocumentSnapshot, FirestoreError, onSnapshot, orderBy, query, QuerySnapshot, where} from 'firebase/firestore';
import {Categorias} from 'interfaces/categorias.interface';
import {Product} from 'interfaces/product.interface';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import {SnapshotObserver} from 'interfaces/observer.interface';
import {UserService} from './user.service';
import {limit} from '@angular/fire/firestore';
import {Functions} from '@angular/fire/functions';

@Injectable({
	providedIn: 'root'
})
export class TiendaService {
	currentNegocio!: Negocio | null;
	private currentNegocio$ = new BehaviorSubject<Negocio | null>(null);
	//prettier-ignore
	constructor(
    private helpers: HelperService,
    private userServ: UserService,
	private functions: Functions,
  ) {}

	getNegocio$() {
		return this.currentNegocio$.asObservable();
	}

	initNegocio(uid: string) {
		return this.helpers.obtenerNegocio$(uid).subscribe((e) => {
			this.currentNegocio = e;
			this.currentNegocio$.next(e);
		});
	}

	/**
	 * Verifica si existe el correo electronico.
	 * @param {any} Service
	 * @returns
	 */
	public checkIfExistsInNegocios = async (campo: string, valor: any): Promise<any> =>
		await this.helpers
			.getInfo('negocios', campo)
			.then((negocios: any[]) =>
				(<Property<Function>>{
					name: (name: string[]) => (name[0] ? {negocioexist: true} : null),
					identificador: (identificador: string[]) => (identificador[0] ? {idexist: true} : null)
				})[campo](negocios.filter((negocio: any) => JSON.stringify(negocio) === JSON.stringify(valor)))
			)
			.catch((error: any) => this.helpers.firebaseErrors({error: error}));

	/**
	 * Verifica si el identificador existe.
	 * @param valor
	 * @returns
	 */
	public checkIndentificador = async (user: {uid: string; identificador: string}) =>
		await this.helpers
			.getInfo('negocios', 'identUID', where('identificador', '==', user.identificador))
			.then((negocios: Negocio[]) => (negocios.length > 0 && user ? (negocios[0].uid === user.uid ? null : {idexist: true}) : null));

	/**
	 * Envia un codigo de verificacion al telefono especificado.
	 * @param num
	 * @param callable
	 * @returns
	 */
	public sendCodeOtp = async (num: string, callable: HttpsCallable = httpsCallable(getFunctions(), 'twilio-sendOtp')) =>
		await callable({phonenumber: num});

	/**
	 * Verifica el codigo enviado.
	 * @param num
	 * @param code
	 * @param callable
	 * @returns
	 */
	public checkCodeOtp = async (num: string, code: string, callable: HttpsCallable = httpsCallable(getFunctions(), 'twilio-checkOtp')) =>
		await callable({phonenumber: num, code: code}).then((res: any) => res.data);

	/**
	 * Obtiene todas las categorias.
	 * @param  {string} negocioId
	 * @returns Promise
	 */
	public obtenerCategorias = async (negocioId: string): Promise<Categorias[]> =>
		await this.helpers.obtenerDocumentos(
			'categorias',
			[],
			where('negocio_id', '==', negocioId),
			where('enable', '==', true),
			orderBy('name', 'asc')
		);

	/**
	 * Obtiene todos los productos.
	 * @param {string} negocioId
	 * @returns Promise
	 */
	public obtenerProductos = async (catId: string): Promise<Product[]> =>
		await this.helpers.obtenerDocumentos('productos', [], where('cat_id', '==', catId), orderBy('name', 'asc'));
	public catHasProduct = async (catId: string): Promise<boolean> =>
		await this.helpers.obtenerDocumentos('productos', [], where('cat_id', '==', catId), limit(1)).then((res) => res.length > 0);

	public async tieneNegocio() {
		const uid = await this.userServ.getUserUid();
		if (!uid) {
			return false;
		}
		// eslint-disable-next-line max-len
		return this.helpers.obtenerDocumentos('negocios', [], where('uid', '==', uid)).then((snap) => snap.length !== 0);
	}

	public getNegocioQr(negocioId: string) {
		return httpsCallable(
			this.functions,
			'negocios-getQrNegocio-getQr'
		)({negocioId: negocioId}).then((res) => {
			console.log(res);
			const data = res.data as {data_uri: string};
			return data.data_uri;
		});
	}
}

