<mensajes></mensajes>

<ion-app>
	<p-toolbar *ngIf="showToolbar()" #toolbarPrincipal [styleClass]="'toolbar-header'">
		<div class="p-toolbar-group-left">
			<!--
			<p-button [styleClass]="'p-button-text text-white menu'" icon="pi pi-bars"></p-button>

      -->
			<!--
        			<div *ngIf="showLogoAndSearchbar()" class="divisor-vertical"></div>

      -->
			<div *ngIf="showLogoAndSearchbar()" class="p-toolbar-fenix-logo fenix-logo-left">
				<a (click)="onPressLogo()">
					<img src="assets/images/Logo_Fenix.png" />
				</a>
			</div>
		</div>
		<div *ngIf="showLogoAndSearchbar()" class="p-toolbar-group-middle fenix-logo-middle">
			<div class="p-toolbar-fenix-logo">
				<a href="https://tienda-online-gratis.app-fenix.com/" target="_self">
					<img src="assets/images/Logo_Fenix.png" />
				</a>
			</div>
		</div>
		<!-- <div *ngIf="showLogoAndSearchbar()" class="p-toolbar-group-middle">
			<div class="searchBar">
				<span class="p-input-icon-right search-input">
					<i class="pi pi-search"></i>
					<input type="text" pInputText />
				</span>
			</div>
		</div> -->
		<div class="p-toolbar-group-right">
			<div class="group-right-wrapper">
				<div class="user-profile">
					<div class="user-profile-wrapper">
						<button type="button" (click)="loggedUser ? closeDrawer('left') : miUsuario.toggle($event)">
							<span class="fenix-user"></span>
							<span>{{ loggedUser?.displayName || 'Identificate' }}</span>
						</button>
						<p-overlayPanel #miUsuario>
							<div class="user-profile-banner">
								<img src="assets/imgmenu.png" alt="banner" />
							</div>
							<div class="botones">
								<button class="azul" type="button" (click)="openEntrarCuenta(miUsuario.toggle($event))">INICIAR SESIÓN</button>
							</div>
							<div class="divisor-horizontal"></div>
							<div class="crear-tienda-content">
								<span>¿Aún no has creado tu usuario?</span>
								<a (click)="openCrearCuenta()">CREALO FACIL!</a>
							</div>
							<div class="divisor-horizontal naranja"></div>
						</p-overlayPanel>
					</div>
				</div>
				<div class="divisor-vertical"></div>
				<div class="mi-tienda">
					<div class="mi-tienda-wrapper">
						<button (click)="miTienda.toggle($event)" type="button">Mi tienda <span class="fenix-store"></span></button>
						<button (click)="miTienda.toggle($event)"><span class="fenix-store"></span></button>
						<p-overlayPanel #miTienda>
							<div class="user-profile-banner">
								<img src="assets/imgmenu.png" alt="banner" />
							</div>
							<div class="tienda-selection">
								<!-- CUANDO EL USUARIO TIENE TIENDA -->
								<ng-container *ngIf="tiendas && tiendas.length > 0; else sinTienda">
									<div class="tienda-selection-wrapper" *ngFor="let tienda of tiendas">
										<div class="tienda-list-content">
											<div class="tienda-list-img">
												<img [src]="tienda?.photo" alt="" />
											</div>
											<div class="tienda-list-name">
												<span>{{ tienda?.name }}</span>
											</div>
										</div>
										<div class="tienda-list-buttons">
											<button class="azul" (click)="mover('/tienda/perfil', miTienda.toggle($event))">IR</button>
										</div>
									</div>
									<div class="tienda-buttons">
										<button class="naranja-extended" [disabled]="true">AÑADIR OTRA CUENTA</button>
									</div>
								</ng-container>
								<!-- CUANDO EL USUARIO NO TIENE TIENDA -->
								<ng-template #sinTienda>
									<div class="tienda-selection-wrapper">
										<span>{{ loggedUser ? 'No se ha creado la tienda' : 'Debes registrarte o iniciar sesión' }}</span>
									</div>
									<div class="tienda-buttons">
										<button
											*ngIf="loggedUser"
											class="naranja-extended"
											type="button"
											(click)="mover('/tienda/crear', miTienda.toggle($event))"
										>
											CREAR TIENDA
										</button>
									</div>
								</ng-template>
							</div>
						</p-overlayPanel>
					</div>
				</div>
				<div class="divisor-vertical" *ngIf="loggedUser"></div>
				<div class="carrito" *ngIf="loggedUser">
					<div class="carrito-wrapper">
						<button type="button" (click)="closeDrawer('right')" [disabled]="loggedUser ? false : true">
							<i class="pi pi-shopping-cart" pBadge size="large" [value]="'' + carrito.length" severity="warning"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</p-toolbar>
	<!-- MENU IZQUIERDO -->

	<!-- MENU IZQUIERDO Y DERECHO -->
	<mat-sidenav-container [hasBackdrop]="" autosize class="w-100 mat-container">
		<mat-sidenav #drawerLeft [disableClose]="false" position="end" class="drawer-left" mode="side">
			<ion-content class="mat-drawer-content-custom">
				<div class="ion-content-wrapper">
					<!-- HEADER DEL MAT-DRAWER -->
					<ion-header class="ion-no-border left">
						<ion-toolbar>
							<div class="header-menu-left">
								<!-- DESHABILITADO TEMPORALMENTE, MIENTRAS SE DEFINE EL BUSCADOR -->
								<div class="search-container">
									<span>Busca algún producto o negocio</span>
									<span class="p-input-icon-right search-container-input">
										<i class="pi pi-search"></i>
										<input type="search" pInputText />
									</span>
								</div>
							</div>
							<ion-button class="drawer-close" fill="clear" color="light" (click)="drawerLeft.toggle()" slot="start">
								<i class="fa-solid fa-angle-left"></i>
								<!-- <i class="fa-solid fa-xmark"></i> -->
							</ion-button>
						</ion-toolbar>
					</ion-header>

					<div class="menu">
						<div class="menu-wrapper">
							<div class="menu-user-info">
								<div class="img-perfil-content">
									<div class="img-perfil">
										<img [src]="loggedUser ? loggedUser.photoURL : 'assets/iconos/user.png'" alt="" />
									</div>
								</div>
								<div class="nombre-perfil-content">
									<div class="nombre-perfil">
										<h3>{{ loggedUser?.displayName }}</h3>
										<h4>{{ loggedUser?.email }}</h4>
									</div>
								</div>
								<ng-container *ngIf="loggedUser; else anonymus">
									<div class="option-perfil-content">
										<div class="option-perfil">
											<button>Editar Usuario</button>
										</div>
										<div class="option-perfil">
											<button>Mis pagos</button>
										</div>
									</div>
								</ng-container>
								<ng-template #anonymus>
									<div class="option-perfil-content">
										<div class="option-perfil">
											<button (click)="goTo('/create-account')">Crear cuenta</button>
										</div>
										<div class="option-perfil">
											<button (click)="goTo('/login-account')">Iniciar sesión</button>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
						<div class="sesion-wrapper" *ngIf="loggedUser">
							<button (click)="cerrarSesion()">Cerrar Sesion</button>
						</div>
					</div>
				</div>
			</ion-content>
		</mat-sidenav>
		<mat-sidenav #drawerRight [disableClose]="false" position="end" class="drawer-right" mode="side">
			<ion-content class="mat-drawer-content-custom">
				<div class="ion-content-wrapper">
					<ion-header class="ion-no-border right">
						<ion-toolbar>
							<ion-button class="drawer-close" fill="clear" color="light" (click)="drawerRight.toggle()" slot="start">
								<i class="fa-solid fa-angle-left"></i>
							</ion-button>
							<div class="header-menu-right">
								<div class="header-right-wrapper">
									<div class="title-container">
										<div>Tu carrito</div>
									</div>
								</div>
							</div>
							<ion-button class="drawer-vaciar" fill="clear" color="light" slot="end" (click)="vaciarCarrito()"> Vaciar </ion-button>
						</ion-toolbar>
					</ion-header>

					<ng-container *ngIf="carrito?.length! > 0; else notProducts">
						<div class="content-carrito carrito-app">
							<p-panel [toggleable]="true" *ngFor="let tienda of carrito; let i = index">
								<ng-template pTemplate="header">
									<div class="tienda">
										<div class="tienda-perfil">
											<img [src]="tienda.photo" alt="" />
										</div>
										<div class="tienda-name">
											<span [routerLink]="ruta(tienda?.identificador)">{{ tienda.tienda }}</span>
											<span>{{ tienda?.productos?.length }}</span>
										</div>
									</div>
								</ng-template>
								<div class="carrito-products" *ngFor="let producto of tienda.productos; let j = index">
									<div class="carrito-products-wrapper">
										<div class="imagen">
											<div class="imagen-wrapper">
												<div class="blur-filter" [style]="'background-image: url(' + producto.imagenes[0] + ');'"></div>
												<img alt="" [src]="producto.imagenes[0]" />
											</div>
										</div>
										<div class="controls-full">
											<div class="controls-wrapper">
												<div class="info">
													<div class="product-name">
														<span> {{ producto.name }} </span>
														<div class="product-delete">
															<button type="button" (click)="eliminarProducto(i, producto, tienda.id)">Eliminar</button>
														</div>
													</div>
												</div>
												<div class="precio-wrapper">
													<div class="precio">{{ precioLocal(producto.precio) }}</div>

													<div class="controls-input-wrapper">
														<p-inputNumber
															#input
															[(ngModel)]="producto.cantidad"
															(onInput)="setCantidad(producto)"
															[showButtons]="true"
															buttonLayout="horizontal"
															inputId="horizontal"
															spinnerMode="horizontal"
															[step]="1"
															[min]="1"
															autocomplete="none"
															[maxlength]="2"
															decrementButtonClass="plusminus-button"
															incrementButtonClass="plusminus-button"
															incrementButtonIcon="pi pi-plus"
															decrementButtonIcon="pi pi-trash"
														></p-inputNumber>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="carrito-options">
									<button type="button" class="transparent" (click)="vaciarCanasta(i)">Vaciar canasta</button>
									<button type="button" class="azul" (click)="verCarrito(tienda.id)">Ver carrito</button>
								</div>
							</p-panel>
						</div>
					</ng-container>
					<ng-template #notProducts>
						<div class="content-carrito">
							<div class="content-carrito-wrapper">
								<div>NO HAY PRODUCTOS AGREGADOS</div>
							</div>
						</div>
					</ng-template>
					<!-- <ion-footer>
						<ion-toolbar>
							<div class="footer-botones">
								<span>
									Para ver todos tus productos
									<br />
									haz clic en "Ver Carrito"
								</span>
								<button class="azul" (click)="goTo('/carrito')">VER CARRITO</button>
							</div>
						</ion-toolbar>
					</ion-footer> -->
				</div>
			</ion-content>
		</mat-sidenav>
		<mat-sidenav-content class="w-100 h-100 drawer-content">
			<div>
				<ion-router-outlet id="main-content"></ion-router-outlet>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>

	<!-- FOOTER COOKIES -->
	<!-- 	<footer *ngIf="activatedBannerCookies">
		<section class="footer-wrapper">
			<div class="gingerbread">
				<div class="gingerbread-wrapper">
					<div class="galleta">
						<img src="assets/index/cookies.png" alt="" />
					</div>
					<div class="cookies">
						<span>Cookies</span>
					</div>
				</div>
			</div>
			<div class="texto">
				<span>Usamos cookies para mejorar tu experiencia. Al hacer clic en Aceptar, nos permite dicho uso.</span>
			</div>
			<div class="botones">
				<button type="button" (click)="acceptCookies()">Aceptar</button>
			</div>
		</section>
	</footer> -->
</ion-app>

